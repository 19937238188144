<template>
  <div class="english-translation-dialog">
    <el-dialog
      title="英文翻译"
      class="english-translation-dialog"
      width="680px"
      top="10vh"
      :visible.sync="value"
      :show-close="showClose"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-table
        :data="tableData"
        row-key="id"
        default-expand-all
        border
        style="width: 100%"
      >
        <el-table-column prop="date" label="字段名称" width="180">
          <template slot-scope="{ row }">{{ row.attributeName }}</template>
        </el-table-column>
        <el-table-column prop="name" label="卖家输入-中文" width="180">
          <template slot-scope="{ row }">{{ row.attributeValue }}</template>
        </el-table-column>
        <el-table-column prop="address" label="商城展示-英文">
          <template slot-scope="{ row }">
            <span v-if="!row.updateFlag">{{ row.attributeValueEn }}</span>
            <el-input
              v-else
              v-model="row.attributeValueEn"
              placeholder="请输入"
              maxlength="50"
              show-word-limit
              v-english-only
              v-filterSpace
              @change="handleInput($event, row)"
            />
            <p
              v-if="(!row.attributeValueEn && isCommit) || row.error"
              class="error"
            >
              {{ errorMsg }}
            </p>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="text-center dialog-footer">
        <el-button type="primary" @click="handleConfirm">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { sellerProduct } from '@/core'
  import { getSellerProductSpecsList } from '@/utils/specs-utils'
  import { mapActions } from 'vuex'
  export default {
    name: 'EnglishTranslationDialog',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      showClose: {
        type: Boolean,
        default: false,
      },
    },
    emit: ['input'],
    directives: {
      'english-only': {
        inserted(el) {
          el.addEventListener('input', function (event) {
            if (event.target.value) {
              const value = event.target.value.replace(/[^\x00-\x7F]/g, '')
              event.target.value = value
            }
          })
        },
      },
    },
    data() {
      return {
        tableData: [],
        englishPattern: '^[a-zA-Z]*$',
        errorMsg: '请输入内容',
        isCommit: false,
        material: '',
        enCnConfigFrozen: [],
      }
    },
    computed: {
      routerQuery() {
        return {
          sellerProductId: this.$route.query.sellerProductId,
          version: this.$route.query.version,
        }
      },
    },
    watch: {
      value(val) {
        if (val) {
          this.tableData.forEach((item) => {
            if (item.attributeType === 'material') {
              item.attributeValueEn = this.material
            }
          })
        }
      },
    },
    methods: {
      ...mapActions({
        setProductSpecsList: 'sellerProduct/setProductSpecsList',
      }),
      async saveMaterial() {
        const curMaterial = this.tableData.find(
          (item) => item.attributeType === 'material'
        )?.attributeValueEn

        const FrozenMaterial = this.enCnConfigFrozen.find(
          (item) => item.attributeType === 'material'
        )?.attributeValueEn

        if (curMaterial !== FrozenMaterial) {
          const data = this.cleanExcessSpace(this.tableData)
          await sellerProduct.saveSellerEnCnConfigVOApi(data)
        }
      },
      async getSellerEnCnConfigVO() {
        const res = await sellerProduct.getSellerEnCnConfigVOApi(
          this.routerQuery?.sellerProductId
        )
        if (res?.code === '000000') {
          // 获取规格项
          this.setProductSpecsList(getSellerProductSpecsList(res?.data))
          this.tableData = res?.data?.map((o) => {
            this.material = res?.data?.find(
              (item) => item.attributeType === 'material'
            )?.attributeValueEn

            let r
            if (o.sellerChild) {
              r = {
                ...o,
                children: o.sellerChild,
              }
            }
            return o.sellerChild ? r : o
          })

          this.enCnConfigFrozen = _.cloneDeep(this.tableData)
          this.$Bus.$emit('getSellerEnCnConfig', res?.data)
        }
      },
      async handleConfirm() {
        this.isCommit = true
        const valid = this.isPropertyEmpty(this.tableData, 'attributeValueEn')
        if (!valid) {
          const data = this.cleanExcessSpace(this.tableData)
          const validateRes = this.validateRepeat(data)
          if (validateRes) {
            return this.$message.warning(validateRes)
          }
          const res = await sellerProduct.saveSellerEnCnConfigVOApi(data)
          if (res?.code === '000000') {
            this.$message.success('保存成功')
            this.$emit('refresh')
            this.getSellerEnCnConfigVO()
            this.handleClose()
          }
        }
      },
      // 去除多余空格
      cleanExcessSpace(data) {
        return _.cloneDeep(data).map((item) => {
          item.attributeValueEn = item.attributeValueEn
            .trim()
            .replace(/\s+/g, ' ')
          if (item.sellerChild?.length) {
            item.sellerChild = this.cleanExcessSpace(item.sellerChild)
          }
          return item
        })
      },
      // 校验是否重复，一级节点和一级比对，二级节点和当前节点的父级下的attributeValueEn比对
      validateRepeat(data) {
        function checkDuplicates(items) {
          const seen = new Map()
          for (const item of items) {
            const value = item.attributeValueEn.toLowerCase()
            if (seen.has(value)) {
              return `已存在${seen.get(value)}`
            }
            seen.set(value, item.attributeValueEn)
          }
          return null
        }
        // 校验一级
        const topLevelResult = checkDuplicates(data)
        if (topLevelResult) {
          return topLevelResult
        }
        // 校验子级
        for (const item of data) {
          if (item.sellerChild) {
            const childResult = checkDuplicates(item.sellerChild)
            if (childResult) {
              return childResult
            }
          }
        }
        // 校验通过
        return null
      },
      handleClose() {
        this.$emit('input', false)
      },
      filterInput(event) {
        const value = event.target.value.replace(/[^a-zA-Z]/g, '')
        this.inputValue = value
      },
      isPropertyEmpty(tree, property) {
        let valid = false
        for (const item of tree) {
          // 检查当前节点的属性
          const value = item[property]
          if (value === null || value === undefined || value === '') {
            valid = true
            break
          }
          // 递归检查子节点
          const children = item.children
          if (children) {
            valid = this.isPropertyEmpty(children, property)
          }

          if (valid) {
            break
          }
        }
        return valid
      },
      handleInput(val, row) {
        if (val) {
          val = val.trim()
          val = val.charAt(0).toUpperCase() + val.slice(1)
        }
        row.attributeValueEn = val
      },
    },
    created() {
      this.getSellerEnCnConfigVO()
      this.$Bus.$on('materialChange', (val) => {
        this.material = val
        this.tableData.forEach((item) => {
          if (item.attributeType === 'material') {
            item.attributeValueEn = val
          }
        })
      })
    },
    mounted() {},
  }
</script>

<style lang="scss" scoped>
  .english-translation-dialog {
    ::v-deep .el-table__body-wrapper {
      max-height: 60vh;
      overflow-y: auto;
      .el-table__expand-icon--expanded {
        display: none;
      }
    }
  }
  ::v-deep .el-table__row--level-1 {
    background: #fff !important;
  }
  ::v-deep .is-leaf {
    &:nth-child(3) {
      position: relative;
      .cell {
        text-indent: 8px;
      }
      &::before {
        content: '*';
        position: absolute;
        color: #f56c6c;
        top: 50%;
        left: 6px;
        transform: translateY(-50%);
        font-size: 14px;
      }
    }
  }
  .error {
    font-size: 12px;
    color: red;
  }
</style>
