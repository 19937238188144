<template>
  <el-row :gutter="20" class="pro-container pro-base-msg pro-spec">
    <!-- <el-button @click="showdata"></el-button> -->
    <el-row>
      <el-col :span="2">
        <!-- 规格设置 -->
        <h6 class="left-h6">{{ $t('productDetail.SpecificationSetting') }}</h6>
      </el-col>
      <el-col :span="22" class="right-content">
        <el-row>
          <label class="mr10">
            {{ $t('productDetail.EnableMultipleSpecifications') }}
          </label>
          <el-switch v-model="isSpecs" disabled></el-switch>
        </el-row>
        <el-row type="flex" class="align_center mt30" v-if="isSpecs">
          <el-form ref="form" :model="form" :rules="rules" inline>
            <!-- 请选择规格 -->
            <el-form-item
              prop="specName"
              :label="$t('productDetail.SelectASpecification')"
              style="margin-bottom: 0"
            >
              <el-select
                v-model="form.specName"
                :placeholder="$t('placeholder.plsSel')"
                disabled
              >
                <el-option
                  v-for="(item, index) of specData"
                  :key="item + '_' + index"
                  :label="item.nameCn"
                  :value="item"
                  :disabled="item.disabled"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </el-row>
        <!-- 规格名称值展示 -->
        <el-table
          :data="tableData"
          stripe
          border
          class="mt30 w100 list-table"
          v-if="isSpecs"
          :key="tableKey"
        >
          <!-- 排序 -->
          <el-table-column
            align="center"
            type="index"
            width="55"
            :label="$t('productDetail.SerialNumber')"
          ></el-table-column>
          <!-- 规格名称 -->
          <el-table-column
            align="center"
            :label="$t('productDetail.SpecificationName')"
            width="150"
            prop="nameCn"
          >
            <template slot-scope="scope">
              {{ scope.row.nameCn }}{{ $specShowSpilterator }}
              {{ getSellerSpecTypeCnWithEn(scope.row.nameCn) }}
            </template>
          </el-table-column>
          <!-- 规格值(可拖动排序) -->
          <el-table-column
            align="center"
            :label="$t('productDetail.SpecificationValue')"
          >
            <template slot-scope="scope" inline-template>
              <el-row align="middle" class="pr20 draggable-row">
                <erp-draggable
                  v-bind="dragOptions"
                  :list="scope.row.value"
                  @end="endClick"
                  class="mb10 ml-10"
                >
                  <template v-for="tag in scope.row.value">
                    <template
                      v-if="
                        pageType == 'edit' &&
                        getStatusFromSpeclist(tag, scope.$index)
                      "
                    >
                      <el-tag
                        class="c_pointer ml10"
                        :disable-transitions="false"
                        v-if="
                          !(
                            downShelfSpecs[scope.$index] &&
                            downShelfSpecs[scope.$index].includes(tag)
                          )
                        "
                      >
                        {{ tag }}{{ $specShowSpilterator
                        }}{{
                          getSellerSpecValueCnWithEn(scope.row.nameCn, tag)
                        }}
                        <el-tooltip
                          class="item"
                          effect="light"
                          :content="$t('productDetail.DownSpecTip')"
                          placement="top-start"
                        >
                          <i
                            class="iconfont icon-xiajia"
                            @click="
                              handleDownOrUp(
                                'down',
                                tag,
                                scope.$index,
                                '',
                                scope.row
                              )
                            "
                          ></i>
                        </el-tooltip>
                      </el-tag>
                    </template>
                    <template v-else>
                      <el-tag
                        class="c_pointer ml10"
                        :closable="scope.row.value.length > 1"
                        :disable-transitions="false"
                        @close="handleClose(scope.row, tag, scope.$index)"
                      >
                        {{ tag }}{{ $specShowSpilterator
                        }}{{
                          getSellerSpecValueCnWithEn(scope.row.nameCn, tag)
                        }}
                      </el-tag>
                    </template>
                  </template>
                </erp-draggable>

                <!-- 编辑的时候加入状态显示 -->
                <template
                  v-if="
                    pageType == 'edit' &&
                    downShelfSpecs.length &&
                    downShelfSpecs[scope.$index] &&
                    downShelfSpecs[scope.$index].length
                  "
                >
                  <el-row type="flex" align="middle" class="down-tag ml10">
                    <span>
                      {{ $t('productDetail.SpecDown') }}
                    </span>
                    <template
                      v-for="(downSpec, index) in downShelfSpecs[scope.$index]"
                    >
                      <el-tag
                        class="c_pointer ml10"
                        :disable-transitions="false"
                        type="info"
                        style="margin-top: 0"
                      >
                        {{ downSpec }}{{ $specShowSpilterator
                        }}{{
                          getSellerSpecValueCnWithEn(scope.row.nameCn, downSpec)
                        }}
                        <i
                          class="iconfont icon-shangjia"
                          @click="
                            handleDownOrUp(
                              'up',
                              downSpec,
                              scope.$index,
                              index,
                              scope.row
                            )
                          "
                        ></i>
                      </el-tag>
                    </template>
                  </el-row>
                </template>

                <el-row type="flex">
                  <!-- 请选择 -->
                  <el-select
                    :placeholder="$t('placeholder.plsSel')"
                    filterable
                    v-model="specSelected[scope.$index]"
                    multiple
                    @change="
                      (arg) => {
                        multipleChooseSpec(
                          arg,
                          scope.row.specOption,
                          scope.$index
                        )
                      }
                    "
                    @visible-change="
                      multipleVisibleChange($event, scope.$index)
                    "
                  >
                    <el-option
                      v-for="(item, index) in scope.row.specOption"
                      :key="item + '_' + index"
                      :label="
                        item.value +
                        $specShowSpilterator +
                        getSellerSpecValueCnWithEn(scope.row.nameCn, item.value)
                      "
                      :value="item.value"
                      :disabled="item.disabled"
                    ></el-option>
                  </el-select>

                  <el-row
                    type="flex"
                    align="middle"
                    style="flex: 1"
                    class="saveTagInput-row"
                  >
                    <!-- 一键清空 -->
                    <span
                      v-if="isAllowClear(scope.row, scope.$index).length"
                      class="f_s_12 ml10 c_pointer"
                      style="
                        color: #1890ff;
                        white-space: nowrap;
                        margin-top: 3px;
                      "
                      @click="handleClear(scope.row, scope.$index)"
                    >
                      {{ $t('other.oneClear') }}
                    </span>
                  </el-row>
                </el-row>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
        <el-row
          class="mt20"
          v-if="tableData && tableData.length > 0 && isSpecs"
        >
          <!-- 规格列表 -->
          <p>{{ $t('productDetail.SpecificationList') }}</p>
          <el-table
            border
            class="mt10"
            :data="specList"
            :key="tableKey"
            :row-class-name="rowClassName"
          >
            <!-- 产品编码 -->
            <el-table-column
              :label="$t('productDetail.ProductCode')"
              prop="sku"
              align="center"
              width="120"
              v-if="pageType === 'edit'"
            ></el-table-column>
            <!-- 规格图 -->
            <el-table-column
              :label="$t('productDetail.SpecificationDrawing')"
              width="200"
              prop="image"
              align="center"
            >
              <template slot-scope="scope">
                <div
                  class="wrapper-box"
                  @click="
                    choseRendingImages(
                      scope.$index,
                      specList[scope.$index].image,
                      1
                    )
                  "
                >
                  <div
                    class="wrapper-image"
                    v-show="specList[scope.$index].image"
                  >
                    <img
                      :src="getFirstImgUrl(specList[scope.$index].image)"
                      width="50px"
                      height="50px"
                      alt
                    />
                  </div>
                  <div
                    class="wrapper-add"
                    v-show="!specList[scope.$index].image"
                  >
                    <el-row>
                      <i class="el-icon-plus"></i>
                    </el-row>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-for="(item, index) in tableData"
              :key="tableKey + index"
              :label="
                item.nameCn +
                $specShowSpilterator +
                getSellerSpecTypeCnWithEn(item.nameCn)
              "
              width="200"
              align="center"
            >
              <template slot-scope="scope">
                <span>
                  {{ scope.row[`specsValue${index + 1}`]
                  }}{{ $specShowSpilterator
                  }}{{
                    getSellerSpecValueCnWithEn(
                      item.nameCn,
                      scope.row[`specsValue${index + 1}`]
                    )
                  }}
                </span>
              </template>
            </el-table-column>
            <!-- 状态 -->
            <el-table-column
              :label="$t('productDetail.Status')"
              prop="specsOptionStatus"
              align="center"
              width="120"
              v-if="pageType === 'edit'"
            >
              <template slot-scope="scope">
                {{
                  getShelfFromDownShelfSpecs(scope.row)
                    ? $t('productDetail.Deactivated')
                    : $t('productDetail.Normal')
                }}
              </template>
            </el-table-column>
            <!-- 备注信息 -->
            <el-table-column
              :label="$t('productDetail.Remarks')"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.remarks"
                  type="textarea"
                  maxlength="500"
                  show-word-limit
                  :autosize="{ minRows: 2, maxRows: 5 }"
                  :placeholder="$t('placeholder.M2004')"
                  @blur="setSpecSync"
                />
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
    </el-row>
    <!-- 选择印刷效果图 -->
    <choose-images
      :visible.sync="chooseImagesDialogVisible"
      :default-file-list="currentDefaultRendingList"
      @confirm="setRendingImages"
      :limit="1"
    ></choose-images>
  </el-row>
</template>

<script>
  import ErpDraggable from 'vuedraggable'
  import ChooseImages from './chooseImages'
  import { elFormErrorScrollIntoView, deepCopy } from 'kits'
  import _ from 'lodash'
  import {
    getSellerSpecTypeCnWithEn,
    getSellerSpecValueCnWithEn,
  } from '@/utils/specs-utils'
  export default {
    name: 'ProductSpecSet',
    components: { ErpDraggable, ChooseImages },

    data() {
      return {
        mockSpecList: [],
        downShelfSpecs: [],
        isAuditHistory: false,
        categoryId: '', //产品分类id
        specData: [], //产品规格
        form: {
          specName: [], //选择的规格名称
        },
        specValue: [], //选择的规格值
        tableData: [], //规格table
        currentEditing: null, //规格input
        specsType: '', //
        specsValue: '',
        stopWatch: false, //停止监听
        isSingleBol: true, //单规格false和多规格判断true,用来判断验证,默认单规格
        editData: [],
        specSelected: [[], []], // 规格选择值
        specList: [], //规格列表
        currentRendingIndex: 0, //
        chooseImagesDialogVisible: false, // 选择规格图片弹窗
        currentDefaultRendingList: [], // 当前选中的规格图片
        currentDefaultRendingListBackups: [], // 当前选中的规格图片，备份
        sortable: null,
        tableKey: '',
        isSpecs: true, // 开启多规格
        pageType: '',
        rules: {
          specName: [
            // 请输入中文名称
            {
              required: true,
              message: this.$t('placeholder.plsSel'),
              trigger: 'change',
            },
          ],
        },
      }
    },

    computed: {
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
    },

    created() {
      this.pageType = this.$route.query.str
      this.tableKey = Math.random()
      this.$Bus.$on('getSellerEnCnConfig', async (data) => {
        this.specData = []
        this.specData = data
          .filter((o) => {
            return o.attributeType === 'specifications'
          })
          .map((p) => {
            return {
              nameCn: p.attributeValueEn,
              disabled: false,
              value: p.sellerChild.map((q) => {
                return q.attributeValueEn
              }),
              specOption: p.sellerChild.map((r) => {
                return {
                  value: r.attributeValueEn,
                  disabled: false,
                }
              }),
            }
          })

        if (
          this.specData &&
          this.tableData &&
          this.specData.length > 0 &&
          this.tableData.length > 0
        ) {
          this.specData.forEach((item, index) => {
            item.specOption.forEach((it, i) => {
              if (
                this.tableData[index]?.value?.includes(it.value) &&
                this.tableData[index]?.specOption[i]?.disabled
              ) {
                this.tableData[index].specOption[i].disabled = true
              } else {
                if (this.tableData[index]?.specOption[i]?.disabled) {
                  this.tableData[index].specOption[i].disabled = false
                }
              }
              if (this.tableData[index]?.specOption[i]?.value) {
                this.tableData[index].specOption[i].value = it.value
              }
            })
          })
        }
      })
    },
    mounted() {
      this.$nextTick(() => {
        this.$refs['form'].clearValidate('specName')
      })
    },

    methods: {
      getSellerSpecTypeCnWithEn,
      getSellerSpecValueCnWithEn,
      async editShow(editData) {
        if (editData) {
          this.form.specName = editData.specsType
          this.editData = editData
          this.isAuditHistory = editData.auditHistory == 1 ? true : false
          if (this.pageType == 'edit') {
            const downShelfSpecs = [[], []]
            editData.specsList.forEach((specItem, index) => {
              if (specItem?.children) {
                specItem?.children.forEach((child) => {
                  if (child.status === 1) {
                    downShelfSpecs[index].push(child?.nameEn)
                  }
                })
              }
            })
            this.downShelfSpecs = downShelfSpecs
            let tempArr = [[], []]
            tempArr.forEach((item, index) => {
              if (!this.downShelfSpecs[index]) {
                this.$set(this.downShelfSpecs, index, [])
              }
            })
          }

          if (
            this.editData.productSpecsEditVOList &&
            (this.editData.productSpecsEditVOList.length > 0) &
              this.specData &
              (this.$route.query.str === 'clone')
          ) {
            this.editData.productSpecsEditVOList =
              this.editData.productSpecsEditVOList.map((it) => {
                if (this.pageType == 'clone') {
                  it.imageId = ''
                  it.shelf = 0
                  it.specsOptionStatus = 0
                }
                return {
                  ...it,
                  sku: '',
                  productSpecsId: '',
                  productId: '',
                }
              })
          }

          this.specList = this.editData.productSpecsEditVOList
          this.mockSpecList = JSON.parse(JSON.stringify(this.specList))

          if (this.editData.specsType === '') {
            this.isSpecs = false
          } else {
            this.isSpecs = true
          }

          await this.reBackSpecs()
        }
      },

      reBackSpecs() {
        this.tableData = []

        let specsType = this.editData.specsType
        let specsValue = this.editData.specsValue

        let specList = []
        specsType.forEach((ot) => {
          this.specData.forEach((it) => {
            if (it?.nameCn === ot) {
              specList.push(it)
            }
          })
        })

        this.tableData = []
        specsType.forEach((item, index) => {
          let obj = {
            disabled: true,
            nameCn: item,
            value: specsValue[index] || [],
            specOption: specList[index]?.specOption,
          }
          this.tableData.push(obj)
        })

        this.tableData.forEach((item) => {
          this.specData.forEach((item2) => {
            if (item?.nameCn === item2?.nameCn) {
              this.$set(item2, 'disabled', true)
              item2.specOption.forEach((it) => {
                if (item.value && item.value.indexOf(it.value) > -1) {
                  this.$set(it, 'disabled', true)
                }
              })
            }
          })
        })

        setTimeout(() => {
          this.$Bus.$emit('specSendBusEdit', this.tableData)
        }, 100)

        this.setSpecSync('edit')
      },

      getStatusFromSpeclist(tag, index) {
        let tempArr = this.specList?.filter(
          (item) => tag == item[index == 0 ? 'specsValue1' : 'specsValue2']
        )

        let obj = tempArr?.find(
          (ele) => ele.shelf === 0 && ele.specsOptionStatus === 1
        )
        return obj
      },

      //从下架规格列表中读取状态
      getShelfFromDownShelfSpecs(row) {
        let isDown = false
        for (let j = 0; j < this.downShelfSpecs.length; j++) {
          for (let k = 0; k < this.downShelfSpecs[j].length; k++) {
            if (
              row.specsValue1 == this.downShelfSpecs[j][k] ||
              row.specsValue2 == this.downShelfSpecs[j][k]
            ) {
              isDown = true
              return isDown
            }
          }
        }
      },

      //上下架操作
      handleDownOrUp(type, tag, $index, index, row) {
        if (type == 'down') {
          if (this.downShelfSpecs[$index]) {
            this.downShelfSpecs[$index].push(tag)
          } else {
            this.downShelfSpecs[$index].push([tag])
          }
        }
        if (type == 'up') {
          this.downShelfSpecs[$index].splice(index, 1)
        }
        if (row.nameCn === 'Color') {
          this.setSpecSync()
        }
      },

      showSpecName() {
        let tempArr = []
        this.tableData.forEach((item) => {
          const nameEn = item.nameCn
          const nameCn = getSellerSpecTypeCnWithEn(nameEn)
          tempArr.push(`${nameEn}${this.$specShowSpilterator}${nameCn}`)
        })
        this.form.specName = tempArr.join(',')
      },

      // 规格值多选，不改变其他逻辑
      multipleChooseSpec(valArr, list, $index) {
        this.currentList = list
        this.currentIndex = $index
      },

      multipleVisibleChange(show, idx) {
        if (!show) {
          this.specSelected[idx].forEach((item) => {
            this.chooseSpec(item, this.currentList, this.currentIndex)
          })
          this.specSelected[idx] = []
          this.currentList = []
          this.currentIndex = ''
        }
      },

      //1.8.0 选择规格值
      chooseSpec(val, list, $index) {
        // 设置规格值不可重复选择
        let selectedSpec = list.find((it) => {
          return it.value === val
        })
        this.$set(selectedSpec, 'disabled', true)
        // 向value 中添加值
        this.tableData[$index].value.push(val)
        this.setSpecList()
        this.setSpecSync()
        this.compareCurrSpecAndOldSpec(val, $index)
      },

      //规格单个tag删除
      handleClose(row, tag) {
        //1.8.0 删除，规格可选择
        let deleted =
          row.specOption &&
          row.specOption.find((it) => {
            return it.value === tag
          })
        if (deleted) {
          this.$set(deleted, 'disabled', false)
        }

        row.value.splice(row.value.indexOf(tag), 1)
        this.setSpecList()
        this.setSpecSync()
      },

      //拖拽结束之后
      endClick() {
        this.setSpecList()
        // this.setSpecSync()
      },

      //清空按钮是否有
      isAllowClear(row, $index) {
        let tempArr = []
        if (row.value && row.value.length) {
          for (let i = row.value.length - 1; i >= 0; i--) {
            if (!this.getStatusFromSpeclist(row.value[i], $index)) {
              tempArr.push(row.value[i])
            }
          }
        }
        return tempArr
      },

      //一键清空
      handleClear(row, $index) {
        let tempArr = []
        for (let i = row.value.length - 1; i >= 0; i--) {
          if (!this.getStatusFromSpeclist(row.value[i], $index)) {
            tempArr.push(row.value[i])
            row.value.splice(i, 1)
          }
        }
        this.$message.success(this.$t('reqmsg.$7'))
        row.specOption &&
          row.specOption.forEach((it) => {
            if (tempArr.length) {
              tempArr.forEach((ele) => {
                if (ele == it.value) {
                  this.$set(it, 'disabled', false)
                }
              })
            } else {
              this.$set(it, 'disabled', false)
            }
          })

        // this.getListSpecByCategoryId()
        this.setSpecList()
        this.setSpecSync()
      },

      // 同步规格值
      setSpecSync(str) {
        this.showSpecName()
        setTimeout(() => {
          this.$Bus.$emit(
            'specSendBus',
            this.tableData,
            this.specList,
            this.downShelfSpecs
          )
        }, 100)
      },

      //比较新旧规格值，如果新的规格值组合和旧的规格值组合值一样，就用旧的值覆盖新的值
      compareCurrSpecAndOldSpec(tag, $index) {
        let specList = this.specList.filter(
          (ele) => tag == ($index == 0 ? ele.specsValue1 : ele.specsValue2)
        )
        specList?.forEach((item) => {
          this.mockSpecList?.forEach((ele) => {
            if (
              item.specsValue1 == ele.specsValue1 &&
              item.specsValue2 == ele.specsValue2
            ) {
              for (const key in ele) {
                this.$set(item, key, ele[key])
              }
              this.$set(item, 'shelf', 0)
              this.$set(item, 'specsOptionStatus', 0)
            }
            if (
              item.specsValue1 == ele.specsValue2 &&
              item.specsValue2 == ele.specsValue1
            ) {
              for (const key in ele) {
                this.$set(item, key, ele[key])
              }
              this.$set(item, 'specsValue1', ele.specsValue2)
              this.$set(item, 'specsValue2', ele.specsValue1)
              this.$set(item, 'shelf', 0)
              this.$set(item, 'specsOptionStatus', 0)
            }
          })
        })
      },

      // 设置规格列表值
      setSpecList(tag) {
        let specArr = []
        /*
        1.无规格值
        2.有一个规格值。specsValue1
        3.有两个规格值，specsValue1，specsValue2
      */
        if (this.tableData.length == 1) {
          this.tableData[0].value?.forEach((jt, j) => {
            let obj = {
              specsValue1: this.tableData[0].value[j],
              specsValue2: '',
            }
            specArr.push(obj)
          })
        }
        if (this.tableData.length == 2) {
          if (this.tableData[0].value.length == 0) {
            this.tableData[1].value?.forEach((jt, j) => {
              let obj = {
                image: '',
                specsValue1: '',
                specsValue2: jt,
              }
              specArr.push(obj)
            })
          }
          if (this.tableData[1].value.length == 0) {
            this.tableData[0].value?.forEach((it, i) => {
              let obj = {
                image: '',
                specsValue1: it,
                specsValue2: '',
              }
              specArr.push(obj)
            })
          }
          if (
            this.tableData[1].value.length !== 0 &&
            this.tableData[0].value.length !== 0
          ) {
            this.tableData[0].value?.forEach((it, i) => {
              this.tableData[1].value?.forEach((jt, j) => {
                let obj = {
                  image: '',
                  specsValue1: it,
                  specsValue2: jt,
                }
                specArr.push(obj)
              })
            })
          }
        }
        if (this.specList?.length > 0) {
          specArr.forEach((it, i) => {
            this.specList.forEach((ot, o) => {
              if (
                // tag === 'drag' &&
                it.specsValue2 === ot?.specsValue1 &&
                it.specsValue1 === ot?.specsValue2
              ) {
                specArr[i] = ot
                specArr[i].specsValue1 = it.specsValue1
                specArr[i].specsValue2 = it.specsValue2
              } else if (
                it.specsValue1 === ot?.specsValue1 &&
                it.specsValue2 === ot?.specsValue2
              ) {
                specArr[i] = ot
                specArr[i].specsValue1 = it.specsValue1
                specArr[i].specsValue2 = it.specsValue2
              }
            })
          })
        }

        this.tableKey = Math.random()
        this.specList = [...specArr]
      },

      //验证
      validateField() {
        let success = false
        let specsType = []
        let specsValue = []
        let delName = []
        let validFlag = false
        if (this.isSpecs) {
          this.$refs.form.validate(async (valid) => {
            if (valid) {
              validFlag = true
            } else {
              success = false
              // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
              this.$nextTick(() => {
                elFormErrorScrollIntoView('.el-form-item__error')
              })
            }
          })
          if (validFlag) {
            this.tableData.forEach((item, index) => {
              specsType.push(item?.nameCn)
              if (item.value.length > 0) {
                specsValue.push(item.value)
              }
            })
            specsType = specsType.join(',')
            specsValue = JSON.stringify(specsValue).replace(/\"/g, "'")
            if (specsType) {
              this.tableData.forEach((item) => {
                if (item.value.length == 0) {
                  delName.push(item.nameCn)
                }
              })
              if (delName.length) {
                success = false
                // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
                this.$nextTick(() => {
                  elFormErrorScrollIntoView('.pro-spec')
                })
                let tip =
                  '规格设置中（' + delName.join(',') + '）对应的的规格值必填'
                this.$baseMessage(
                  tip,
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
              } else {
                success = true
              }
            }
          }
        } else {
          success = true
          // 单规格
          specsType = ''
          specsValue = ''
        }

        if (success) {
          return {
            success: true,
            specsType,
            specsValue,
            specsSwitch: this.isSpecs ? 1 : 0,
            downShelfSpecs: JSON.stringify(this.downShelfSpecs),
            specsList: this.getSpecsList(),
          }
        } else {
          return {
            success: false,
          }
        }
      },
      // 获取specsList接口提交参数
      getSpecsList() {
        return this.tableData?.map((item) => {
          return {
            nameCn: getSellerSpecTypeCnWithEn(item?.nameCn),
            nameEn: item?.nameCn,
            status: item?.status || 0,
            children:
              item.value.map((valueItem) => {
                const downShelfSpecs = this.downShelfSpecs.flat(1)
                const isDown = downShelfSpecs.find(
                  (downSpecs) => downSpecs === valueItem
                )
                return {
                  nameCn: getSellerSpecValueCnWithEn(item?.nameCn, valueItem),
                  nameEn: valueItem,
                  // status: valueItem?.status || 0,
                  status: isDown ? 1 : 0,
                }
              }) || [],
          }
        })
      },
      //保存到本地
      validateField2() {
        let specsType = []
        let specsValue = []
        this.tableData.forEach((item, index) => {
          specsType.push(item?.nameCn)
          specsValue.push(item.value)
        })
        specsType = specsType.join(',')
        specsValue = JSON.stringify(specsValue).replace(/\"/g, "'")
        // 单规格
        if (!this.isSpecs) {
          specsType = ''
          specsValue = ''
        }
        return {
          downShelfSpecs: JSON.stringify(this.downShelfSpecs),
          specsType,
          specsValue,
          specsSwitch: this.isSpecs ? 1 : 0,
          specsList: this.getSpecsList(),
        }
      },

      // 获取第一张图片
      getFirstImgUrl(urls) {
        return urls ? urls.split(',')[0] : ''
      },

      // 显示选择印刷效果图弹窗
      choseRendingImages(index, urls) {
        this.currentRendingIndex = index
        this.currentDefaultRendingList = []
        this.chooseImagesDialogVisible = true
        if (urls) {
          let _urls = urls.split(',')
          for (let i = 0; i < _urls.length; i++) {
            this.currentDefaultRendingList.push({
              url: _urls[i],
              name: '',
            })
          }
          this.currentDefaultRendingListBackups = deepCopy(
            this.currentDefaultRendingList
          )
        }
      },

      setRendingImages(data) {
        this.$set(this.specList[this.currentRendingIndex], 'image', data.urls)
        this.$set(this.specList[this.currentRendingIndex], 'imageId', '')
        this.setSpecSync()
      },

      //根据规格列表中状态为1的列让该行变为灰色
      rowClassName({ row }) {
        let flag = this.getShelfFromDownShelfSpecs(row)
        if (flag && this.pageType == 'edit') {
          // 变颜色的条件
          return 'rowName' // 这个return的就是样式 可以是color 也可以是backgroundColor
        }
      },

      // 清空数据
      clearData() {
        this.form.specName = ''
        this.tableData = []
        this.specList = []

        this.specData.forEach((it) => {
          this.$set(it, 'disabled', false)
          this.$set(it, 'value', it.specOption)
        })
        this.setSpecSync()
      },
    },

    beforeDestroy() {
      // 关闭$bus
      this.$Bus.$off('specProMsg')
    },
    watch: {
      'form.specName': function (val) {
        if (!val) {
          this.$Bus.$emit('clearSpec')
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .pro-container {
    .left-h6 {
      display: flex;
      font-size: 16px;
      font-family: 'PingFang Bold';
    }

    .left-h6::before {
      content: '';
      display: block;
      height: 16px;
      width: 2px;
      background: #282c34;
      margin-right: 5px;
      margin-top: 4px;
    }

    .right-content {
      height: 100%;
      border: 1px solid #e4e7ed;
      padding: 20px;
      border-radius: 3px;
    }
  }

  .draggable-row {
    > div {
      text-align: left;
    }
  }

  ::v-deep {
    .el-tag {
      margin-top: 5px;
    }

    .el-tag + .el-tag {
      margin-left: 10px;
    }

    .saveTagInput-row {
      .el-input--small .el-input__inner {
        height: 32px !important;
      }
    }

    .down-tag {
      font-size: 12px;
      margin-top: 10px;
    }
  }

  .wrapper-box {
    .wrapper-add {
      display: inline-block;
      width: 50px;
      height: 50px;
      text-align: center;
      border: 1px dotted #c0cbda;
      border-radius: 3px;
      cursor: pointer;

      .el-row {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        span {
          font-size: 12px;
          color: #9e9e9e;
        }

        i {
          color: #9e9e9e;
        }
      }
    }
  }

  .sort {
    text-align: center;
    cursor: all-scroll;

    i {
      font-size: 18px;
    }
  }

  .iconfont {
    cursor: pointer;
    font-size: 14px;
  }

  .icon-xiajia {
    color: #1890ff;
  }

  .ml-10 {
    margin-left: -10px;
  }
</style>
<style>
  .rowName {
    background-color: #f5f7fa !important;
  }
</style>
